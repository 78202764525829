<script>
	import Base from '@backend/Base.vue';
	import draggable from 'vuedraggable'
	import BOGen from '@helper/BOGen';
	export default {
		extends: Base,
		components: {
			draggable,
		},
		data() {
			return {
				Name: "About",
				about: {},
				banner: {},
				row: {},
			}
		},
		computed: {
			
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		methods: {
			submitFormHero(e, callback) {
				if (e && e.btnLoading()) return;
				this.banner.type = 'editBanner'
				BOGen.apirest("/" + this.Name, this.banner, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-banner')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-banner').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			submitForm(e, callback) {
				if (e && e.btnLoading()) return;
				this.about.type = 'editAbout'
				BOGen.apirest("/" + this.Name, this.about, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info-about')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							this.refreshData()
						}, 2000)
						return Gen.info(resp.message, "success", 1000, '.info-about').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
			addTim() {
				
				this.row = {}
				this.row = {
					at_is_active: "Y"
				}
				this.row.type = 'add'
			},
			editTime(v) {
				$('#collapseExampleTeam').addClass('show')
				this.row = v;
				this.row.type = 'update'
			},
			deleteItemId(id) {
				var v = id
				swal({
					title: "Are you sure to delete " + this.ObjectName + "?",
					text: "",
					icon: "warning",
					buttons: ["No, Cancel!", "Yes, Sure!"],
					dangerMode: true,
				}).then((ok) => {
					if (ok) {
						BOGen.apirest("/" + this.Name, {
							type: "delete",
							id: v
						}, () => {
							swal("Deleted!", "Information has been deleted.", "success");
							this.refreshData()
						}, "POST")
					}
				});
			},
			submitTeam(e, callback) {
				if (e && e.btnLoading()) return;
				BOGen.apirest("/" + this.Name, this.row, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) {
						if (err.status == 422) {
							Object.keys(err.responseJSON).forEach((k) => {
								$("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
							})
						}
						return
					}
					if (resp.error) {
						if (this.errorFormCallback) return this.errorFormCallback(resp);
						if (callback) return callback(resp);
						return Gen.info(resp.message, "danger", 1000, '.info')
					}
					if (resp.success) {
						if (this.successFormCallback) return this.successFormCallback(resp);
						if (callback) return callback(resp);
						setTimeout(() => {
							this.refreshData()
						}, 2000)
						this.row = {}
						$('#collapseExampleTeam').addClass('hide')
						return Gen.info(resp.message, "success", 1000, '.info').then(() => {
							this.$router.push({
								name: this.Name,
								params: {
									id: this.$route.params.id
								}
							})
						})
					}
				}, "POST")
			},
		},
		watch: {}
	};
</script>
<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<div class="row mt-2">
			<!-- Start Contact -->
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Hero</h5>
						<VForm @resp="submitFormHero" method="post">
							<div class="row">
								<div class="col-md-12 info-banner"></div>
								<div class="col-md-6">
									<BoField name="asi_img" mandatory label="Banner Image">
										<Uploader :param="{thumbnail:true}" name="asi_img" v-model="banner.asi_img"
											type="hero" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="asi_title" v-model="banner.asi_title" mandatory :attr="{minlength:3,maxlength:100,required:true}" :help="'Maximium 100 charcters.'"></BoField>
									<BoField name="asi_desc" mandatory :help="'Maximium 150 charcters.'">
										<textarea class="form-control" required="" rows="5" maxlength="150" minlength="5" v-model="banner.asi_desc"></textarea>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','hero')"
										class="btn btn-rounded btn-info btn-loading">Update Banner</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">About Us</h5>
						<VForm @resp="submitForm" method="post">
							<div class="row">
								<div class="col-md-12 info-about"></div>
								<div class="col-md-6">
									<BoField name="sc_background" mandatory label="Description Image">
										<Uploader :param="{thumbnail:true}" name="sc_background" v-model="about.sc_background"
											type="hero" uploadType="cropping">
										</Uploader>
									</BoField>
								</div>
								<div class="col-md-6">
									<BoField name="sc_title" v-model="about.sc_title" mandatory :attr="{minlength:3,maxlength:100,required:true}" :help="'Maximium 100 charcters.'"></BoField>
									<BoField name="sc_desc" mandatory>
										<CKEditor name="sc_desc" isRequired class="form-control" v-model="about.sc_desc">
										</CKEditor>
									</BoField>
								</div>
								<div class="col-md-12 text-right">
									<button type="submit" @click="$set(input,'ctasubmit','description')" class="btn btn-rounded btn-info btn-loading">Update Description</button>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>

			<!-- List Tim -->
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<h5 class="card-title">Our Team</h5>
						<div class="row" tag="tbody">
							<div style="cursor:move;" class="col-md-3" :id="'dat'+v.as_id" v-for="(v,k) in data.data" :key="k">
								<div class="wrap_slider_img">
									<img :src="uploader(v.at_img)" class="img-responsive" />
									<div class="slider_name">
										<p>{{v.at_name}}</p>
									</div>
									<a data-toggle="collapse" @click="editTime(v)" href="javascript:;" class="bullet_edit"><i
											class="fas fa-pencil-alt"></i></a>
									<a href="javascript:;" @click="deleteItemId(v.at_id,'dat'+v.at_id)" class="bullet_delete"><i
											class="far fa-trash-alt"></i></a>
									<span class="label label-success" v-if="v.at_is_active == 'Y'">Active</span>
									<span class="label label-danger" v-else>Inactive</span>
								</div>
							</div>

							<div class="col-md-3">
								<a class="wrap_upload" data-toggle="collapse" @click="addTim" href="#collapseExampleTeam" role="button"
									aria-expanded="false" aria-controls="collapseExampleTeam">
									<div class="ic_wrap">
										<i class="fas fa-plus"></i>
										<p>New Tim</p>
									</div>
								</a>
							</div>
						</div>
						<VForm @resp="submitTeam" method="post">
							<div class="row collapse mt-4" id="collapseExampleTeam">
								<div class="col-12 info"></div>
								<div class="col-md-4 mb-3">
									<BoField name="at_img">
										<Uploader name="at_img" :param="{thumbnail:true}" :attr="{required:1}" v-model="row.at_img" type="users" uploadType="cropping"></Uploader>
									</BoField>
								</div>
								<div class="col-md-8 mb-3">
									<div class="row">
										<div class="col-md-12">
											<div class="panel_group">
												<div class="panel_heading">Data</div>
												<div class="panel_body">
													<div class="row">
														<div class="col-md-6">
															<BoField name="at_name" v-model="row.at_name" :help="'Maximal 100 characters.'"></BoField>
															<BoField name="at_position" v-model="row.at_position" :help="'Maximal 100 characters.'"></BoField>
															<BoField name="at_is_active">
																<div class="row">
																	<radio name="at_is_active" v-model="row.at_is_active" option="Y"
																		:attr="validation('at_is_active')">
																		Active</radio>
																	<radio name="at_is_active" v-model="row.at_is_active" option="N">
																		Inactive</radio>
																</div>
															</BoField>
														</div>

														<div class="col-md-6">
															<BoField name="at_in" v-model="row.at_in"></BoField>
															<BoField name="at_linkin" v-model="row.at_linkin"></BoField>
															<BoField name="at_fb" v-model="row.at_fb"></BoField>															
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="col-12 mt-3 text-right">
											<button type="submit" class="btn btn-rounded btn-info btn-loading">Submit Team</button>
										</div>
									</div>
								</div>
							</div>
						</VForm>
					</div>
				</div>
			</div>

		</div>
	</div>
</template>